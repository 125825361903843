import { gql } from "@apollo/client";

export const GET_SURVEY = gql`
  query GetSurvey($id: String!) {
    getSurvey(id: $id) {
      id
      teamId
      name
      logo
      logoAlignment
      backgroundColor
      backgroundImage
      buttonColor
      buttonTextColor
      font
      fontColor
      restartSurvey
      fontInfo {
        family
        isGoogleFont
      }
      status
      createdBy
      createdAt
      updatedAt
      active
      teamWhiteLabelText
      teamWhiteLabelLogo
      questions {
        id
        type
        question
        mandatory
        skippable
        skippableText
        skipRoute
        icon
        buttonText
        nextOnSelect
        emoticon4Options
        minimumSelects
        presetDataSetId
        routeToQuestion
        presetDataSetId
        fieldName
        theEnd
        presetDataSet {
          presetDataSetDatas {
            id
            name
          }
        }
        externalLink
        sortOrder
        createdBy
        answers {
          id
          data {
            ... on EMOTICON_FIELDS {
              icon
              answerText
              routeToQuestion
            }
            ... on MULTI_SELECT_FIELDS {
              answerText
            }
            ... on SINGLE_SELECT_FIELDS {
              answerText
              routeToQuestion
            }
          }
          sortOrder
        }
      }
    }
  }
`;
