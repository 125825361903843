import { Text, TextProps } from "@chakra-ui/react";
import React from "react";
import { useAppSelector } from "../../redux/store";

const CustomText: React.FC<TextProps> = ({ children, ...rest }) => {
  const theme = useAppSelector((state) => state.theme.theme);
  return (
    <Text color={theme?.textColor} {...rest}>
      {children}
    </Text>
  );
};

export default CustomText;
