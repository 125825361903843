import { motion, Variants } from "framer-motion";
import React, { ReactNode, FC } from "react";
import { useAppSelector } from "../../redux/store";
import { IAnimationEvent } from "../../types";

const variants: Variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 800 : -800,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: () => {
    return {
      zIndex: 0,
      opacity: 0,
      transition: {
        type: "easeInOut",
        duration: 0.2,
      },
    };
  },
};

type Props = {
  children: ReactNode;
} & IAnimationEvent;

const AnimatedStep: FC<Props> = ({
  children,
  onAnimationStart,
  onAnimationComplete,
}) => {
  const { direction } = useAppSelector((state) => state.survey);

  return (
    <motion.div
      custom={direction}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        type: "easeInOut",
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 2,
      }}
      onAnimationStart={onAnimationStart}
      onAnimationComplete={onAnimationComplete}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedStep;
