export const globalStyle = {
  html: {
    scrollBehavior: "smooth",
    h: "100%",
  },
  body: {
    color: "black",
    h: "100%",
    maxWidth: "100vw",
  },
  "#root": {
    h: "100%",
  },
};
