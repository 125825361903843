import { Select } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { IQuestionProps } from "../../../types";
import { useForm } from "react-hook-form";
import QuestionWrapper from "../../QuestionWrapper";

const PresetData: React.FC<IQuestionProps> = ({
  defaultValue,
  onSubmit,
  onDirty,
  isHideSubmit,
  ...restProps
}) => {
  const { id, routeToQuestion, options, nextOnSelect, mandatory } = restProps;

  const routeId = routeToQuestion;

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      value: defaultValue as string,
    },
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (isDirty && onDirty) {
      onDirty();
    }
  }, [isDirty, onDirty]);

  const buttonSubmitRef = useRef<HTMLButtonElement>(null);

  return (
    <form
      id={`question-${id}`}
      onSubmit={handleSubmit((formData) => {
        if (onSubmit) {
          onSubmit(formData.value as string, false, false, routeId);
        }
      })}
    >
      <QuestionWrapper
        isValid={isValid}
        submitButtonProps={{
          display: isHideSubmit ? "none" : "block",
          disabled: !isValid && !nextOnSelect,
          ref: buttonSubmitRef,
        }}
        {...restProps}
      >
        <Select
          placeholder="Select"
          size={{ base: "sm", lg: "lg" }}
          width="auto"
          _focus={{
            background: "#ffffffcc",
          }}
          variant="filled"
          {...register("value", {
            required: mandatory,
            onChange: () => {
              if (nextOnSelect) {
                buttonSubmitRef.current?.click();
              }
            },
          })}
        >
          {options?.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </QuestionWrapper>
    </form>
  );
};

export default PresetData;
