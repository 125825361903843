import { useSurveyContext } from "../context/SurveyContext";

const useHeaderHeight = () => {
  const { bottomInfoRef } = useSurveyContext();

  const bottomInfoHeight = bottomInfoRef?.current?.clientHeight || 0;

  return bottomInfoHeight;
};

export default useHeaderHeight;
