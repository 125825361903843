import { Badge } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { IQuestionProps } from "../../../types";
import { Controller, useForm } from "react-hook-form";
import Options from "./Options";
import QuestionWrapper from "../../QuestionWrapper";

const MultiSelect: React.FC<IQuestionProps> = ({
  defaultValue,
  onSubmit,
  onDirty,
  ...restProps
}) => {
  const { id, options, minimumSelects, mandatory, routeToQuestion } = restProps;

  const {
    handleSubmit,
    formState: { errors, isDirty, isValid },
    trigger,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: { value: defaultValue as string[] },
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (isDirty && onDirty) {
      onDirty();
    }
  }, [isDirty, onDirty]);

  const routeId = routeToQuestion;

  return (
    <form
      id={`question-${id}`}
      onSubmit={handleSubmit((formData) => {
        if (onSubmit) {
          onSubmit(formData.value as string[], false, false, routeId);
        }
      })}
    >
      <QuestionWrapper isValid={isValid} {...restProps}>
        <Controller
          control={control}
          rules={{
            required: mandatory,
            validate: (val) => {
              if (minimumSelects && val.length < minimumSelects) {
                return `Minimum selection is ${minimumSelects}`;
              }
            },
          }}
          name="value"
          render={({ field: { onChange, value } }) => (
            <>
              {options && (
                <Options options={options} onChange={onChange} value={value} />
              )}
            </>
          )}
        />
        {isDirty &&
          errors.hasOwnProperty("value") &&
          (errors.value?.type as unknown as string) === "validate" && (
            <Badge colorScheme="red" mb="5">
              {errors.value?.message as unknown as string}
            </Badge>
          )}
      </QuestionWrapper>
    </form>
  );
};

export default MultiSelect;
