import { ChakraProvider } from "@chakra-ui/react";
import theme from "./app/theme";
import MainPage from "./app/pages/main";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Fonts from "./app/theme/Fonts";
import { HelmetProvider } from "react-helmet-async";

export const App = () => {
  const helmetContext = {};

  return (
    <ChakraProvider theme={theme}>
      <Fonts />

      <BrowserRouter>
        <HelmetProvider context={helmetContext}>
          <Switch>
            <Route
              path="/:surveyId"
              strict={true}
              render={() => <MainPage />}
            />
            <Redirect from="*" to="/" />
          </Switch>
        </HelmetProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
};
