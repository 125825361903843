import { Input } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { IQuestionProps } from "../../../types";
import { useForm } from "react-hook-form";
import QuestionWrapper from "../../QuestionWrapper";

const SingleInput: React.FC<IQuestionProps> = ({
  defaultValue,
  onSubmit,
  onDirty,
  ...restProps
}) => {
  const { id, mandatory, routeToQuestion } = restProps;

  const routeId = routeToQuestion;

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      value: defaultValue,
    },
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (isDirty && onDirty) {
      onDirty();
    }
  }, [isDirty, onDirty]);

  return (
    <form
      id={`question-${id}`}
      onSubmit={handleSubmit((formData) => {
        if (onSubmit) {
          onSubmit(formData.value as string, false, false, routeId);
        }
      })}
    >
      <QuestionWrapper isValid={isValid} {...restProps}>
        <Input
          size={{ base: "sm", lg: "lg" }}
          variant="filled"
          {...register("value", { required: mandatory })}
        />
      </QuestionWrapper>
    </form>
  );
};

export default SingleInput;
