import { Checkbox, SimpleGrid, useCheckboxGroup } from "@chakra-ui/react";
import React from "react";
import { IOption } from "../../../types";
import { useAppSelector } from "../../../redux/store";

interface EmoticonsProps {
  options: IOption[];
  value?: string[] | number[];
  onChange?: (value: string[] | number[]) => void;
}

const Options: React.FC<EmoticonsProps> = ({ options, value, onChange }) => {
  const theme = useAppSelector((state) => state.theme.theme);

  const { getCheckboxProps } = useCheckboxGroup({
    value,
    onChange,
  });

  return (
    <SimpleGrid
      columns={{ base: 1 }}
      spacingY={{
        base: options.length > 6 ? "15px" : "21px",
        lg: options.length > 6 ? "15px" : "20px",
      }}
      color={theme.textColor}
      px={{ base: "20px", lg: "20px" }}
      w={{ lg: "100%" }}
    >
      {options.map((option, index) => (
        <Checkbox
          size={{ base: "sm", lg: "lg" }}
          key={option.value}
          alignItems="flex-start"
          spacing="15px"
          sx={{
            ".chakra-checkbox__control": {
              marginTop: { base: "2px", lg: "9px" },
            },
          }}
          {...getCheckboxProps({ value: option.value })}
        >
          {option.label}
        </Checkbox>
      ))}
    </SimpleGrid>
  );
};

export default Options;
