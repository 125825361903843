import { ComponentStyleConfig } from "@chakra-ui/react";

const Checkbox: ComponentStyleConfig = {
  baseStyle: {
    control: {
      fontWeight: 400,
      background: "white",
      fontSize: "14px",
      border: "1px solid",
      borderColor: "#000000",
      _checked: {
        borderColor: "#000000",
      },
    },
    _focus: {
      boxShadow: "none",
    },
  },
  sizes: {
    lg: {
      label: {
        fontSize: "24px",
      },
      control: {
        borderRadius: "4px",
        width: "20px",
        height: "20px",
      },
    },
    sm: {
      control: {
        borderRadius: "3px",
        width: "20px",
        height: "20px",
      },
      label: {
        fontSize: "16px",
      },
    },
  },
  defaultProps: {
    size: "sm",
  },
};

export default Checkbox;
