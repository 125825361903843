import React from "react";
import { Box } from "@chakra-ui/react";
import { useSurveyContext } from "../../context/SurveyContext";

const BottomInfo: React.FC = () => {
  const { bottomInfoRef } = useSurveyContext();

  return (
    <Box
      color="#fff"
      bg="#f83232"
      py="8px"
      px="8px"
      fontSize="0.94rem"
      textAlign="center"
      lineHeight="1.5"
      ref={bottomInfoRef}
    >
      This survey hasn't published yet! The data inputted here will not be saved
      to the responses!
    </Box>
  );
};

export default BottomInfo;
