import { Input, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IQuestionProps } from "../../../types";
import { useForm } from "react-hook-form";
import QuestionWrapper from "../../QuestionWrapper";
//import CustomButton from "../../Button";
import { useAppSelector } from "../../../redux/store";

const DatetimePicker: React.FC<IQuestionProps> = ({
  defaultValue,
  onSubmit,
  onDirty,
  ...restProps
}) => {
  const { id, mandatory, routeToQuestion } = restProps;

  const theme = useAppSelector((state) => state.theme.theme);

  const routeId = routeToQuestion;

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
    watch,
    reset,
    setValue,
    trigger,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (defaultValue) {
      const datas = (defaultValue as string).split(" ");
      const formValue = {
        date: "",
        time: "",
      };
      if (datas[1]) {
        const times = datas[1].split(":");
        formValue.time = `${times[0]}:${times[1]}`;
      }

      formValue.date = datas[0];

      reset(formValue);
    }
  }, [defaultValue, reset]);

  useEffect(() => {
    if (isDirty && onDirty) {
      onDirty();
    }
  }, [isDirty, onDirty]);

  const watchDate = watch("date");
  const watchTime = watch("time");

  const [localValue, setLocalValue] = useState("");

  useEffect(() => {
    setLocalValue(`${watchDate} ${watchTime}:00`);
  }, [watchDate, watchTime]);

  useEffect(() => {
    const setTimeNow = () => {
      const now = new Date();

      let date = now.getDate().toString();
      let month = (now.getMonth() + 1).toString();
      const year = now.getFullYear().toString();

      if (month.length < 2) month = "0" + month;
      if (date.length < 2) date = "0" + date;

      const hours = now.getHours();
      const minutes = now.getMinutes();

      setValue("date", `${year}-${month}-${date}`);
      setValue(
        "time",
        `${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}`
      );

      trigger("date");
    };

    setTimeNow();
  }, [setValue, trigger, theme]);

  return (
    <form
      id={`question-${id}`}
      onSubmit={handleSubmit((formData) => {
        if (onSubmit) {
          onSubmit(localValue as string, false, false, routeId);
        }
      })}
    >
      <QuestionWrapper isValid={isValid} {...restProps}>
        <VStack
          spacing={{
            base: "16px",
            lg: "20px",
          }}
        >
          <Input
            type="date"
            size={{ base: "sm", lg: "lg" }}
            {...register("date", { required: mandatory })}
          />

          <Input
            type="time"
            size={{ base: "sm", lg: "lg" }}
            {...register("time")}
          />
        </VStack>
        {/*<CustomButton
          variant="clean"
          bgColor="transparent"
          _hover={{ bgColor: "transparent" }}
          _active={{ bgColor: "transparent" }}
          color={theme.textColor}
          size="sm"
          mt="20px"
          onClick={setTimeNow}
          fontSize={{
            lg: "24px",
          }}
        >
          Set date & time to Now
        </CustomButton>*/}
      </QuestionWrapper>
    </form>
  );
};

export default DatetimePicker;
