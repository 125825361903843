import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { useWizard } from "use-wizard";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  Box,
  VStack,
} from "@chakra-ui/react";
import QuestionTypes from "./QuestionTypes";
import { IQuestion, IQuestionWizardSubmit, ISurvey } from "../../../types";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  setAnimationComplete,
  setAnimationStart,
  setAnswer,
  setDirection,
  setIsFinished,
  setSelectedQuestionId,
  setSurveyResponse,
  clearAnswers,
} from "../../../redux/reducers/survey";
import { useMutation } from "@apollo/client";
import {
  SURVEY_RESPONSE_COMPLETE,
  SURVEY_RESPONSE_START,
} from "../../../graphql/mutations";
import SurveyStyles from "./SurveyStyles";
import { AnimatePresence } from "framer-motion";
import QuestionLayout from "../../../components/layouts/QuestionLayout";
import useQueryParams from "../../../hooks/useQueryParams";
import CustomButton from "../../../components/Button";
import { QuestionsWizardProvider } from "../../../context/QuestionsWizardContext";

const QuestionsWizard: React.FC<{
  survey: ISurvey;
  questions: IQuestion[];
}> = ({ survey, questions }) => {
  const dispatch = useAppDispatch();

  const {
    answers,
    surveyResponse,
    isFinished,
    isQuestionAsFinishedScreen,
    totalQuestions,
  } = useAppSelector((state) => state.survey);

  const queryParams = useQueryParams();

  const { theme } = useAppSelector((state) => state.theme);

  const [surveyResponseStart] = useMutation(SURVEY_RESPONSE_START);

  const [surveyResponseComplete, { loading: isLoadingComplete }] = useMutation(
    SURVEY_RESPONSE_COMPLETE
  );

  const [step, wizard] = useWizard(questions.map((elm) => elm.id));

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [lastIndex, setLastIndex] = useState(0);
  useEffect(() => {
    if (wizard.stepIndex() === currentQuestionIndex) {
      setLastIndex(currentQuestionIndex);
    }
  }, [currentQuestionIndex, wizard]);

  useEffect(() => {
    dispatch(setSelectedQuestionId(step));
  }, [step, dispatch]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [callback, setCallback] = useState<() => void>();

  const questionContainerRef = useRef<HTMLDivElement>(null);

  const onFinished = useCallback(
    async (
      responseId: string,
      answers?: { [key: string]: string | string[] },
      isQuestionAsFinishedScreen?: boolean
    ) => {
      const anwersMapping = answers
        ? Object.keys(answers).map((key) => {
            return [
              key,
              Array.isArray(answers[key])
                ? (answers[key] as Array<string>).join("|")
                : answers[key].toString(),
            ];
          })
        : [];

      await surveyResponseComplete({
        variables: {
          input: {
            id: responseId,
            answers: anwersMapping,
          },
        },
      });

      onClose();
      dispatch(setIsFinished({ isFinished: true, isQuestionAsFinishedScreen }));
      if (callback !== undefined) {
        callback();
      }
    },
    [callback, onClose, surveyResponseComplete, dispatch]
  );

  const onSubmit: IQuestionWizardSubmit = (
    id,
    data,
    isFinish,
    isQuestionAsFinishScreen,
    nextRoute
  ) => {
    dispatch(setAnswer({ key: id, value: data }));
    questionContainerRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    if (isFinish || wizard.stepIndex() + 1 >= wizard.wizardLength()) {
      /// finish
      if (survey.status === "DRAFT") {
        onOpen();
      } else {
        const finishAnswers = { ...answers };
        finishAnswers[id] = data;
        if (surveyResponse) {
          onFinished(
            surveyResponse.id,
            finishAnswers,
            isQuestionAsFinishScreen
          );
        }
      }
    } else if (nextRoute && !isFinish) {
      wizard.goToStep(nextRoute);
      dispatch(setDirection(1));
      if (callback !== undefined) {
        callback();
      }
    } else {
      wizard.nextStep();
      dispatch(setDirection(1));
      if (callback !== undefined) {
        callback();
      }
    }
  };

  const onDirty = useCallback(async () => {
    try {
      if (surveyResponse === undefined) {
        const { data } = await surveyResponseStart({
          variables: {
            input: {
              surveyId: survey.id,
            },
          },
        });

        dispatch(setSurveyResponse(data.surveyResponseStart));

        // finish directly if only 1 question
        if (totalQuestions <= 1) {
          onFinished(data.surveyResponseStart.id, answers);
        }
      }
    } catch (err: any) {
      console.log("__err", err.message);
    }
  }, [
    dispatch,
    survey,
    surveyResponse,
    surveyResponseStart,
    answers,
    onFinished,
    totalQuestions,
  ]);

  useEffect(() => {
    if (survey && survey.fontInfo && survey.fontInfo.isGoogleFont) {
      window.WebFont.load({
        google: {
          families: [`${survey.font}:400`],
        },
      });
    }
  }, [survey]);

  const closingTitle = useMemo(() => {
    let title =
      "You reached the last question of the survey. Click submit button if you wish to finish this survey.";

    if (survey.status === "DRAFT") {
      title =
        "This survey is a draft. You cannot submit this survey until it is published.";
    }

    return title;
  }, [survey]);

  const closingActionButtons = useMemo(() => {
    let actions = (
      <>
        <Button variant="ghost" mr={3} onClick={onClose}>
          Close
        </Button>
        <Button
          colorScheme="blue"
          onClick={() => {
            if (surveyResponse) {
              onFinished(surveyResponse.id, answers);
            }
          }}
          isLoading={isLoadingComplete}
          disabled={isLoadingComplete || !surveyResponse}
        >
          Submit
        </Button>
      </>
    );

    if (survey.status === "DRAFT") {
      actions = (
        <>
          <Button variant="ghost" mx="auto" onClick={onClose}>
            Close
          </Button>
        </>
      );
    }

    return actions;
  }, [survey, isLoadingComplete, onClose, surveyResponse, onFinished, answers]);

  const restartSurvey = () => {
    dispatch(
      setIsFinished({ isFinished: false, isQuestionAsFinishedScreen: false })
    );
    dispatch(clearAnswers());
    dispatch(setSurveyResponse(undefined));

    const firstStep = questions[0].id;
    wizard.goToStep(firstStep);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{closingTitle}</Text>
          </ModalBody>

          <ModalFooter>{closingActionButtons}</ModalFooter>
        </ModalContent>
      </Modal>

      <Box
        ref={questionContainerRef}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize={"cover"}
        p={{ base: "24px 25px 21px 21px", lg: "66px 96px 33px 100px" }}
        h="100%"
        overflow={
          questions[wizard?.stepIndex()].isAnimationRunning ? "hidden" : "auto"
        }
        position="relative"
        bgColor={survey.backgroundColor}
        bgImage={
          survey.backgroundImage ? `url(${survey.backgroundImage})` : undefined
        }
      >
        <QuestionsWizardProvider restartSurvey={restartSurvey}>
          <QuestionLayout wizard={wizard} step={step}>
            <AnimatePresence>
              {/*!isFinished &&
              isQuestionAsFinishScreen &&
              questions?.map((question, index) => {
                if (step === question.id) {
                  return (
                    <QuestionTypes
                      {...question}
                      key={question.id}
                      index={index}
                      wizard={wizard}
                      defaultValue={answers && answers[question.id]}
                      onSubmit={(data, isFinish, nextRoute, callback) => {
                        onSubmit(question.id, data, isFinish, nextRoute);
                        setCallback(() => callback);
                      }}
                      onDirty={onDirty}
                      lastIndex={lastIndex}
                      onAnimationStart={() => {
                        dispatch(setAnimationStart(index));
                      }}
                      onAnimationComplete={() => {
                        dispatch(setAnimationComplete(index));
                        setCurrentQuestionIndex(index);
                      }}
                    />
                  );
                }

                return null;
              })*/}

              {isFinished && !isQuestionAsFinishedScreen ? (
                <VStack
                  position="absolute"
                  top="50%"
                  transform="translateY(-50%)"
                  spacing="20px"
                >
                  <Box
                    textAlign="center"
                    fontSize="1.4rem"
                    color={theme.textColor}
                  >
                    Your submission has been registered!
                  </Box>
                  {survey.restartSurvey && (
                    <CustomButton
                      onClick={() => {
                        restartSurvey();
                      }}
                    >
                      Restart
                    </CustomButton>
                  )}
                </VStack>
              ) : (
                questions?.map((question, index) => {
                  if (step === question.id) {
                    let defaultValue = undefined;
                    if (answers && answers.hasOwnProperty(question.id)) {
                      defaultValue = answers[question.id];
                    } else if (question.fieldName) {
                      defaultValue =
                        queryParams.get(question.fieldName) || undefined;
                    }
                    return (
                      <QuestionTypes
                        {...question}
                        key={question.id}
                        index={index}
                        wizard={wizard}
                        defaultValue={defaultValue}
                        onSubmit={(
                          data,
                          isFinish,
                          isQuestionAsFinishScreen,
                          nextRoute,
                          callback
                        ) => {
                          onSubmit(
                            question.id,
                            data,
                            isFinish,
                            isQuestionAsFinishScreen,
                            nextRoute
                          );
                          setCallback(() => callback);
                        }}
                        onDirty={onDirty}
                        lastIndex={lastIndex}
                        onAnimationStart={() => {
                          dispatch(setAnimationStart(index));
                        }}
                        onAnimationComplete={() => {
                          dispatch(setAnimationComplete(index));
                          setCurrentQuestionIndex(index);
                        }}
                      />
                    );
                  }

                  return null;
                })
              )}
            </AnimatePresence>
          </QuestionLayout>
        </QuestionsWizardProvider>
      </Box>

      <SurveyStyles fontFamily={survey.font} />
    </>
  );
};

export default QuestionsWizard;
