import AngryEmoji from "../../SVG/AngryEmoji";
import FrownEmoji from "../../SVG/FrownEmoji";
import AverageEmoji from "../../SVG/AverageEmoji";
import GoodEmoji from "../../SVG/GoodEmoji";
import GreatEmoji from "../../SVG/GreatEmoji";
import { useAppSelector } from "../../../redux/store";

const useDefaultIcons = () => {
  const { theme } = useAppSelector((state) => state.theme);

  const defaultValues = [
    {
      icon: (
        <GreatEmoji
          color={theme.buttonBackgroundColor}
          transition="all .4s ease"
        />
      ),
      copy: "Very Happy",
    },
    {
      icon: (
        <GoodEmoji
          color={theme.buttonBackgroundColor}
          transition="all .4s ease"
        />
      ),
      copy: "Happy",
    },
    {
      icon: (
        <AverageEmoji
          color={theme.buttonBackgroundColor}
          transition="all .4s ease"
        />
      ),
      copy: "OK",
    },
    {
      icon: (
        <FrownEmoji
          color={theme.buttonBackgroundColor}
          transition="all .4s ease"
        />
      ),
      copy: "Unhappy",
    },
    {
      icon: (
        <AngryEmoji
          color={theme.buttonBackgroundColor}
          transition="all .4s ease"
        />
      ),
      copy: "Very Unhappy",
    },
  ];

  return defaultValues;
};

export default useDefaultIcons;
