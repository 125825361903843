import { ComponentStyleConfig } from "@chakra-ui/react";

const Button: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 500,
    color: "white",
    fontSize: "20px",
    borderRadius: "8px",
    px: { lg: "20px", base: "20px" },
    py: { base: "16px", lg: "20px" },
    maxW: "unset",
    height: "auto",
    h: "auto",
    w: "auto",
    width: "auto",
    minH: "unset",
    _focus: {
      boxShadow: "none",
    },
    _hover: {
      opacity: "1",
      color: "white",
    },
    transition: "opacity 320ms ease-in-out",
    minW: "unset",
  },
  sizes: {
    lg: {
      fontSize: "32px",
      px: "30px",
      py: "20px",
      height: "unset",
      minHeight: "78px",
      minWidth: "260px",
      borderRadius: "10px",
    },
    sm: {
      fontSize: "16px",
      borderRadius: "8px",
      px: "20px",
      py: "16px",
      minHeight: "54px",
      minWidth: "80px",
    },
  },
  defaultProps: {
    size: "sm",
  },
  variants: {
    link: {
      bgColor: "transparent",
      _hover: {
        textDecoration: "none",
      },
    },
    clean: {
      p: "0",
      minHeight: "0",
      minWidth: "0",
    },
  },
};

export default Button;
