import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const AngryEmoji: React.FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M24 0C10.6875 0 0 10.7812 0 24C0 37.3125 10.6875 48 24 48C37.2188 48 48 37.3125 48 24C48 10.7812 37.2188 0 24 0ZM13.4062 22.5C13.4062 21.6562 13.7812 20.9062 14.3438 20.3438L11.5312 19.5C10.7812 19.2188 10.3125 18.375 10.5 17.625C10.7812 16.7812 11.625 16.4062 12.375 16.5938L19.875 18.8438C20.7188 19.125 21.0938 19.9688 20.9062 20.7188C20.7188 21.375 20.0625 21.75 19.5 21.75C19.4062 21.75 19.3125 21.75 19.3125 21.75C19.4062 22.0312 19.5 22.3125 19.5 22.5C19.5 24.1875 18.0938 25.5938 16.5 25.5938C15 25.5938 13.4062 24.1875 13.4062 22.5ZM29.1562 37.0312C26.625 33.8438 21.2812 33.8438 18.6562 37.0312C17.4375 38.5312 15.0938 36.5625 16.4062 35.0625C18.2812 32.8125 21 31.5 23.9062 31.5C26.9062 31.5 29.625 32.8125 31.5 35.0625C32.8125 36.6562 30.4688 38.5312 29.1562 37.0312ZM36.375 19.5L33.5625 20.3438C34.0312 20.9062 34.5 21.6562 34.5 22.5C34.5 24.1875 33.0938 25.5 31.5 25.5C29.8125 25.5 28.5 24.1875 28.5 22.5C28.5 22.3125 28.5938 22.0312 28.6875 21.75C28.5938 21.75 28.5 21.75 28.5 21.75C27.8438 21.75 27.1875 21.375 27 20.7188C26.8125 19.9688 27.2812 19.125 28.0312 18.8438L35.5312 16.5938C36.2812 16.4062 37.125 16.875 37.4062 17.625C37.5938 18.375 37.2188 19.2188 36.375 19.5Z"
        fill="currentColor"
      />
    </Icon>
  );
};

AngryEmoji.defaultProps = {
  viewBox: "0 0 48 48",
  boxSize: { base: "38px", lg: "40px" },
};

export default AngryEmoji;
