import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const GoodEmoji: React.FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M24 0C10.6875 0 0 10.7812 0 24C0 37.3125 10.6875 48 24 48C37.2188 48 48 37.3125 48 24C48 10.7812 37.2188 0 24 0ZM31.5 16.5C33.0938 16.5 34.4062 17.9062 34.4062 19.5C34.4062 21.1875 33.0938 22.5 31.5 22.5C29.8125 22.5 28.4062 21.1875 28.4062 19.5C28.4062 17.9062 29.8125 16.5 31.5 16.5ZM16.4062 16.5C18 16.5 19.4062 17.9062 19.4062 19.5C19.4062 21.1875 18 22.5 16.4062 22.5C14.7188 22.5 13.4062 21.1875 13.4062 19.5C13.4062 17.9062 14.8125 16.5 16.4062 16.5ZM34.6875 32.5312C32.0625 35.7188 28.125 37.5 24 37.5C19.7812 37.5 15.8438 35.625 13.2188 32.4375C11.9062 30.9375 14.25 28.9688 15.4688 30.4688C17.625 33.0938 20.7188 34.5 24 34.5C27.2812 34.5 30.2812 33.0938 32.4375 30.5625C33.6562 29.0625 36 30.9375 34.6875 32.5312Z"
        fill="currentColor"
      />
    </Icon>
  );
};

GoodEmoji.defaultProps = {
  viewBox: "0 0 48 48",
  boxSize: { base: "38px", lg: "40px" },
};

export default GoodEmoji;
