import { ComponentStyleConfig } from "@chakra-ui/react";

const Input: ComponentStyleConfig = {
  baseStyle: {
    field: {
      fontWeight: 400,
      bgColor: "white",
      color: "rgba(0, 0, 0, 0.7)",
      fontSize: "14px",
      borderRadius: "4px",
    },
  },
  sizes: {
    lg: {
      field: {
        fontSize: "24px",
        minHeight: "55px",
        borderRadius: "10px",
        minWidth: "660px",
        boxShadow: " 0px 8px 24px 2px rgba(0, 0, 0, 0.25)",
      },
    },
    sm: {
      field: {
        fontSize: "14px",
        borderRadius: "4px",
        minHeight: "28px",
        minWidth: "285px",
        boxShadow: "0px 4px 18px 1px rgba(0, 0, 0, 0.25)",
      },
    },
  },
  variants: {
    filled: {
      field: {
        border: "2px solid",
        background: "white",
        _focus: {
          bgColor: "#ffffffcc",
        },
        _hover: {
          bgColor: "#ffffffcc",
        },
      },
    },
  },
  defaultProps: {
    size: "sm",
    variant: "filled",
  },
};

export default Input;
