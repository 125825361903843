import React, { useEffect, useMemo } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { GET_SURVEY } from "../../graphql/queries";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setQuestions, setSurvey } from "../../redux/reducers/survey";
import QuestionsWizard from "./components/QuestionsWizard";
import { ISurvey, QuestionType, SurveyStatus } from "../../types";
import { getImage } from "../../helpers/global";
import { storeThemeSettings } from "../../redux/reducers/theme";
import { Helmet } from "react-helmet-async";
import { SurveyProvider } from "../../context/SurveyContext";
import BottomInfo from "../../components/BottomInfo";

const MainPage: React.FC = () => {
  const { surveyId }: { surveyId: string } = useParams();

  const { loading, data } = useQuery(GET_SURVEY, {
    variables: { id: surveyId },
  });

  const dispatch = useAppDispatch();

  const { survey, questions } = useAppSelector((state) => state.survey);

  useEffect(() => {
    if (data && data.getSurvey) {
      const surveyInfo = data.getSurvey as ISurvey;

      dispatch(
        setSurvey({
          ...data.getSurvey,
          backgroundImage: surveyInfo.backgroundImage
            ? getImage(surveyInfo.backgroundImage)
            : "",
        })
      );

      dispatch(
        setQuestions(
          surveyInfo.questions.map(
            ({
              id,
              type,
              question,
              mandatory,
              skippable,
              skippableText,
              skipRoute,
              icon,
              buttonText,
              nextOnSelect,
              emoticon4Options,
              minimumSelects,
              presetDataSetId,
              presetDataSet,
              externalLink,
              routeToQuestion,
              sortOrder,
              theEnd,
              fieldName,
              answers,
            }: any) => {
              let options = [];

              if (type === QuestionType.PRESET_DATA) {
                options = presetDataSet.presetDataSetDatas.map((dt: any) => ({
                  value: dt.id,
                  label: dt.name,
                }));
              } else if (
                type === QuestionType.MULTI_SELECT ||
                type === QuestionType.EMOTICON ||
                type === QuestionType.SINGLE_SELECT
              ) {
                options = answers.map((elm: any) => {
                  return {
                    value: elm.id,
                    label: elm.data.answerText,
                    icon: elm.data.icon ? getImage(elm.data.icon) : "",
                    routeId: elm.data.routeToQuestion,
                  };
                });
              }

              // make 'mailto' clickable
              var regex = /\[mailto:(.*?)\](.*?)\[\/mailto]/;
              var mailtoString: any;
              if ((mailtoString = regex.exec(question))) {
                question = question.replace(
                  mailtoString[0],
                  `<a href='mailto:${mailtoString[1]}'><strong><u>${mailtoString[2]}</u></strong></a>`
                );
              }

              return {
                id,
                type,
                copy: question,
                mandatory,
                skip_question: skippable,
                skip_copy: skippableText,
                skipRoute,
                icon: icon ? getImage(icon) : "",
                button_copy: buttonText,
                nextOnSelect,
                emoticon4Options,
                routeToQuestion,
                minimumSelects,
                presetDataSetId,
                externalLink,
                sortOrder,
                theEnd,
                fieldName,
                answers,
                options,
              };
            }
          ) || []
        )
      );

      dispatch(
        storeThemeSettings({
          backgroundColor: surveyInfo.backgroundColor || "white",
          logoPosition: surveyInfo.logoAlignment || "RIGHT",
          primaryColor: "#E5007F",
          textColor: surveyInfo.fontColor || "black",
          buttonBackgroundColor: surveyInfo.buttonColor || "white",
          buttonColor: surveyInfo.buttonTextColor || "black",
        })
      );
    }
  }, [data, dispatch]);

  const isSurveyAvailable = useMemo(() => {
    return survey && questions.length >= 1;
  }, [survey, questions]);

  return (
    <>
      <Helmet>
        <title>{data?.getSurvey?.name}</title>
      </Helmet>
      <SurveyProvider>
        <Box h="100%">
          {loading && <Text>Loading...</Text>}

          {!loading && isSurveyAvailable && (
            <Flex direction="column" h="100%">
              <QuestionsWizard
                survey={survey as ISurvey}
                questions={questions}
              />

              {survey?.status !== SurveyStatus.PUBLISHED && <BottomInfo />}
            </Flex>
          )}

          {!loading && !isSurveyAvailable && <Text>Survey Not Found!</Text>}
        </Box>
      </SurveyProvider>
    </>
  );
};

export default MainPage;
