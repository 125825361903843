import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const GreatEmoji: React.FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M24 0C10.6875 0 0 10.7812 0 24C0 37.3125 10.6875 48 24 48C37.2188 48 48 37.3125 48 24C48 10.7812 37.2188 0 24 0ZM31.5 16.5C33.0938 16.5 34.4062 17.9062 34.4062 19.5C34.4062 21.1875 33.0938 22.5 31.5 22.5C29.8125 22.5 28.4062 21.1875 28.4062 19.5C28.4062 17.9062 29.8125 16.5 31.5 16.5ZM16.4062 16.5C18 16.5 19.4062 17.9062 19.4062 19.5C19.4062 21.1875 18 22.5 16.4062 22.5C14.7188 22.5 13.4062 21.1875 13.4062 19.5C13.4062 17.9062 14.8125 16.5 16.4062 16.5ZM24 40.5C18.2812 40.5 11.3438 36.9375 10.5 31.7812C10.3125 30.6562 11.3438 29.8125 12.375 30.0938C15.2812 31.0312 19.5 31.5 24 31.5C28.5 31.5 32.625 31.0312 35.5312 30.0938C36.5625 29.8125 37.5938 30.6562 37.4062 31.7812C36.5625 36.9375 29.625 40.5 24 40.5Z"
        fill="currentColor"
      />
    </Icon>
  );
};

GreatEmoji.defaultProps = {
  viewBox: "0 0 48 48",
  boxSize: { base: "38px", lg: "40px" },
};

export default GreatEmoji;
