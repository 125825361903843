import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Text,
  chakra,
  shouldForwardProp,
} from "@chakra-ui/react";
import BackArrow from "../SVG/BackArrow";
import NextArrow from "../SVG/NextArrow";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setDirection } from "../../redux/reducers/survey";
import { AnimatePresence, isValidMotionProp, motion } from "framer-motion";
import { HeaderProps } from "./types";

const MotionBack = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const MotionSkip = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const LogoCenter: React.FC<Partial<HeaderProps>> = ({
  logo,
  back_copy,
  skip_copy,
  skipRoute,
  skip_color,
  back_color,
  skip_question,
  wizard,
}) => {
  const dispatch = useAppDispatch();

  const showPreviousStep = (wizard?.nPreviousSteps as number) >= 1;

  const showSkipButton =
    (wizard?.stepIndex() as number) < (wizard?.wizardLength() as number) - 1 &&
    skip_question;

  const { isFinished } = useAppSelector((state) => state.survey);

  return (
    <>
      <Box zIndex={2} display={{ lg: "none" }} mb="4">
        <Image src={logo} maxH={{ base: "50px", lg: "75px" }} mx="auto" />
      </Box>
      <Box
        w="100%"
        display={{ base: "none", lg: "flex" }}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          svg: {
            height: "14px",
          },
          "@media (min-width: 64rem)": {
            svg: {
              height: "24px",
            },
          },
        }}
        fontSize={{ base: "14px", lg: "24px" }}
        mb={{ base: 5, lg: 10 }}
      >
        <Flex
          flex={1}
          justifyContent={{
            base: showSkipButton ? "flex-start" : "flex-end",
            lg: "flex-start",
          }}
          position="relative"
        >
          <AnimatePresence>
            {!isFinished && showPreviousStep && (
              <MotionBack
                layout
                initial={{
                  x: -150,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                }}
                exit={{
                  x: -150,
                  opacity: 0,
                }}
              >
                <HStack
                  spacing={{ base: "2px", lg: "4px" }}
                  alignItems="center"
                  as={Button}
                  minH="0"
                  variant="link"
                  onClick={() => {
                    wizard?.previousStep();
                    dispatch(setDirection(-1));
                  }}
                  transform={{
                    base: showSkipButton ? "translateX(0)" : "translateX(50%)",
                    lg: "translateX(0)",
                  }}
                >
                  <BackArrow color={back_color} />
                  <Text color={back_color}>{back_copy}</Text>
                </HStack>
              </MotionBack>
            )}
          </AnimatePresence>
        </Flex>

        <Box display={{ base: "none", lg: "block" }}>
          <Image src={logo} maxH={{ base: "50px", lg: "75px" }} mx="auto" />
        </Box>

        <Flex flex={1} justifyContent="flex-end" h="100%">
          <AnimatePresence>
            {showSkipButton && (
              <MotionSkip
                initial={{
                  x: 150,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                }}
                exit={{
                  x: 150,
                  opacity: 0,
                }}
                // @ts-ignore
                transition={{
                  duration: 0.6,
                }}
              >
                <Button
                  minHeight="0"
                  variant="link"
                  onClick={() => {
                    if (skipRoute) {
                      wizard?.goToStep(skipRoute);
                    } else {
                      wizard?.nextStep();
                    }

                    dispatch(setDirection(1));
                  }}
                >
                  <HStack
                    spacing={{ base: "2px", lg: "4px" }}
                    alignItems="center"
                  >
                    <Text color={skip_color}>{skip_copy || "Skip"}</Text>
                    <NextArrow color={skip_color} />
                  </HStack>
                </Button>
              </MotionSkip>
            )}
          </AnimatePresence>
        </Flex>
      </Box>
    </>
  );
};

export default LogoCenter;
