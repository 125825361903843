import { Box } from "@chakra-ui/react";
import { useSurveyContext } from "../../context/SurveyContext";
import LogoCenter from "./LogoCenter";
import LogoNotCenter from "./LogoNotCenter";
import { HeaderProps } from "./types";

const Header: React.FC<Partial<HeaderProps>> = (props) => {
  const { logo_position } = props;

  const { headerRef } = useSurveyContext();

  return (
    <Box ref={headerRef} position="relative">
      {logo_position === "CENTER" ? (
        <LogoCenter {...props} />
      ) : (
        <LogoNotCenter {...props} />
      )}
    </Box>
  );
};

export default Header;
