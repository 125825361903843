import {
  from,
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import cookie from "js-cookie";
import { tokenRetrievalRequest } from "./global";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  credentials: "include",
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

const authMiddleWare = new ApolloLink((operation, forward) => {
  if (!cookie.get("XSRF-TOKEN")) {
    tokenRetrievalRequest()
      .then(() => {
        operation.setContext(({ headers }: any) => ({
          ...headers,
        }));
      })
      .catch(() => {
        cookie.set("XSRF-TOKEN", "");
      });
  }

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  ssrMode: typeof window === "undefined",
  link: from([authMiddleWare, retryLink, httpLink]),
});

export const noCacheClient = new ApolloClient({
  cache: new InMemoryCache(),
  ssrMode: typeof window === "undefined",
  link: from([authMiddleWare, retryLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

export default client;
