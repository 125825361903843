import React, { createContext, FC, useRef, useContext, ReactNode } from "react";

interface IAppContext {
  headerRef?: React.RefObject<HTMLDivElement>;
  bottomInfoRef?: React.RefObject<HTMLDivElement>;
}

const defaultState = {
  headerRef: undefined,
  bottomInfoRef: undefined,
};

const SurveyContext = createContext<IAppContext>(defaultState);

interface SurveyProviderProps {
  children: ReactNode;
}
const SurveyProvider: FC<SurveyProviderProps> = ({ children }) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const bottomInfoRef = useRef<HTMLDivElement>(null);

  return (
    <SurveyContext.Provider value={{ headerRef, bottomInfoRef }}>
      {children}
    </SurveyContext.Provider>
  );
};

const useSurveyContext = () => useContext(SurveyContext);

export { SurveyProvider, useSurveyContext };
