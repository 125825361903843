import { motion } from "framer-motion";
import {
  Box,
  Image,
  useRadio,
  Text,
  RadioProps,
  Stack,
} from "@chakra-ui/react";
import { useAppSelector } from "../../../redux/store";

const MotionStack = motion(Stack);

type RadioIconProps = {
  copy?: string;
  icon?: string;
  defaultIcon: JSX.Element;
} & RadioProps;

const RadioIcon: React.FC<RadioIconProps> = ({
  copy,
  icon,
  defaultIcon,
  ...radioProps
}) => {
  const theme = useAppSelector((state) => state.theme.theme);

  const emoticonVariants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "easeInOut",
        duration: 0.5,
      },
    },
  };

  const { getInputProps, getCheckboxProps } = useRadio(radioProps);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box
      as="label"
      cursor={"pointer"}
      _hover={{
        "img,svg": {
          transform: {
            base: "scale(1.86)",
            md: "scale(1.86) translateY(-6px)",
          },
          transformOrigin: { base: "center", md: "none" },
          transition: "all .2s ease",
        },
      }}
    >
      <input {...input} />
      <MotionStack
        {...checkbox}
        direction={{ base: "row", md: "column" }}
        alignItems="center"
        spacing={{ base: "30px", md: "12px" }}
        variants={emoticonVariants}
        cursor="pointer"
        outline="none"
        _checked={{
          "img,svg": {
            transform: {
              base: "scale(1.86)",
              md: "scale(1.86) translateY(-6px)",
            },
            transformOrigin: { base: "center", md: "none" },
            transition: "all .2s ease",
          },
        }}
      >
        {icon && (
          <Image
            src={icon}
            alt={copy}
            width={{ base: "38px", lg: "48px" }}
            height={{ base: "38px", lg: "48px" }}
            transition="all .4s ease"
          />
        )}
        {!icon && defaultIcon}
        <Text
          color={theme?.textColor}
          textAlign="center"
          fontSize={{ base: "18px", lg: "20px" }}
          lineHeight={{ base: 1.4 }}
        >
          {copy}
        </Text>
      </MotionStack>
    </Box>
  );
};

export default RadioIcon;
