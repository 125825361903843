import React from "react";
import { Global } from "@emotion/react";

const SurveyStyles: React.FC<{ fontFamily: string }> = ({ fontFamily }) => {
  return (
    <Global
      styles={`
      body {
        font-family: '${fontFamily}';
        margin: 0;
        color: #212529
      }`}
    />
  );
};

export default SurveyStyles;
