import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import Button from "./components/Button";
import Checkbox from "./components/CheckBox";
import Input from "./components/Input";
import Select from "./components/Select";
import Textarea from "./components/TextArea";
import { globalStyle } from "./GlobalStyle";

const breakpoints = createBreakpoints({
  sm: "375px", // mobile
  md: "768px", // Tablet
  lg: "1024px", // Tablet
  xl: "1440px", // Desktop
  xxl: "1500px",
  xxxl: "2560px",
});

const theme = extendTheme({
  fonts: {
    heading: "gilroy",
    body: "gilroy",
  },
  styles: {
    global: globalStyle,
  },
  components: {
    Button,
    Select,
    Checkbox,
    Textarea,
    Input,
  },
  //   fonts,
  breakpoints,
});

export default theme;
