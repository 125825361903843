import { Box, Button, Flex, HStack, Image, Text } from "@chakra-ui/react";
import BackArrow from "../SVG/BackArrow";
import NextArrow from "../SVG/NextArrow";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setDirection } from "../../redux/reducers/survey";
import { HeaderProps } from "./types";

const LogoNotCenter: React.FC<Partial<HeaderProps>> = ({
  logo,
  back_copy,
  skip_copy,
  skipRoute,
  logo_position,
  skip_color,
  back_color,
  skip_question,
  wizard,
}) => {
  const dispatch = useAppDispatch();

  const showPreviousStep = (wizard?.nPreviousSteps as number) >= 1;

  const showSkipButton =
    (wizard?.stepIndex() as number) < (wizard?.wizardLength() as number) - 1 &&
    skip_question;

  const { isFinished } = useAppSelector((state) => state.survey);

  return (
    <Flex
      w="100%"
      alignItems="center"
      justifyContent="space-between"
      flexDirection={logo_position === "RIGHT" ? "row-reverse" : "row"}
      sx={{
        svg: {
          height: "14px",
        },
        "@media (min-width: 64rem)": {
          svg: {
            height: "24px",
          },
        },
      }}
      fontSize={{ base: "14px", lg: "24px" }}
      flexWrap="wrap"
    >
      <Image src={logo} maxH={{ base: "50px", lg: "75px" }} />
      <Box display={{ base: "none", lg: "block" }}>
        <HStack
          spacing={{ base: "9px", lg: "24px" }}
          ml={logo_position === "LEFT" ? "auto" : ""}
          mr={logo_position === "RIGHT" ? "auto" : ""}
        >
          {!isFinished && showPreviousStep && (
            <HStack spacing="12px">
              <Box
                visibility={{ lg: showPreviousStep ? "visible" : "hidden" }}
                display={{
                  base: showPreviousStep ? "block" : "none",
                  lg: "block",
                }}
              >
                <HStack
                  spacing={{ base: "2px", lg: "4px" }}
                  alignItems="center"
                  as={Button}
                  variant="link"
                  onClick={() => {
                    wizard?.previousStep();
                    dispatch(setDirection(-1));
                  }}
                >
                  <BackArrow color={back_color} />
                  <Text color={back_color}>{back_copy}</Text>
                </HStack>
              </Box>
            </HStack>
          )}
          {showSkipButton && (
            <HStack
              spacing={{ base: "2px", lg: "4px" }}
              alignItems="center"
              as={Button}
              variant="link"
              onClick={() => {
                if (skipRoute) {
                  wizard?.goToStep(skipRoute);
                } else {
                  wizard?.nextStep();
                }

                dispatch(setDirection(1));
              }}
            >
              <Text color={skip_color}>{skip_copy || "Skip"}</Text>
              <NextArrow color={skip_color} />
            </HStack>
          )}
        </HStack>
      </Box>
    </Flex>
  );
};

export default LogoNotCenter;
