import { BoxProps, ButtonProps, LinkProps } from "@chakra-ui/react";
import { IWizard } from "use-wizard/lib/cjs/useWizard/types/IWizard";
import { TStep } from "use-wizard/lib/cjs/useWizard/types/TStep";

export interface IStep {
  icon?: string;
  button_copy: string;
  copy_alignment?: "left" | "right" | "center";
  copy: string;
  button_icon?: boolean;
  previousStepIndex: React.MutableRefObject<number>;
  wizard: IWizard;
  step: TStep;
  skip_copy?: string;
  select_options?: string[];
  options?: string[];
}

export type QuestionLayoutProps = BoxProps & {
  bg_color?: string;
  wizard?: IWizard;
  step?: TStep;
};

export enum QuestionType {
  STATEMENT = "STATEMENT",
  EMOTICON = "EMOTICON",
  MULTI_SELECT = "MULTI_SELECT",
  SINGLE_SELECT = "SINGLE_SELECT",
  COMMENT_BOX = "COMMENT_BOX",
  SINGLE_INPUT = "SINGLE_INPUT",
  DATETIME_PICKER = "DATETIME_PICKER",
  PRESET_DATA = "PRESET_DATA",
}

export interface IOption {
  value: string;
  label: string;
  icon?: string;
  routeId?: string;
}

export enum SurveyStatus {
  "PUBLISHED" = "PUBLISHED",
  "DRAFT" = "DRAFT",
  "ARCHIVED" = "ARCHIVED",
}

export interface ISurvey {
  id: string;
  name: string;
  logo: string;
  logoAlignment: "LEFT" | "CENTER" | "RIGHT";
  backgroundColor: string;
  backgroundImage: string;
  buttonColor: string;
  buttonTextColor: string;
  font: string;
  fontColor: string;
  restartSurvey: string;
  fontInfo: {
    family: string;
    isGoogleFont: boolean;
  };
  questions: IQuestion[];
  status: SurveyStatus;
  teamWhiteLabelText: string;
  teamWhiteLabelLogo: string;
}

export type IQuestionSubmit = (
  value: string | string[],
  isFinish?: boolean,
  isQuestionAsFinishScreen?: boolean,
  nextRoute?: string,
  callback?: () => void
) => void;

export type IQuestionWizardSubmit = (
  id: string,
  value: string | string[],
  isFinish?: boolean,
  isQuestionAsFinishScreen?: boolean,
  nextRoute?: string
) => void;

export interface IQuestion {
  id: string;
  type: QuestionType;
  mandatory: boolean;
  minimumSelects?: number;
  icon?: string;
  button_copy: string;
  copy_alignment?: "left" | "right" | "center";
  copy: string;
  button_icon?: boolean;
  skip_question?: boolean;
  skip_copy?: string;
  skipRoute?: string;
  select_options?: string[];
  answers?: any;
  options?: IOption[];
  isLast?: boolean;
  nextOnSelect?: boolean;
  emoticon4Options?: boolean;
  routeToQuestion?: string;
  externalLink?: string;
  theEnd?: boolean;
  fieldName?: string;
  isAnimationRunning?: boolean;
}

export type IQuestionProps = IQuestion & { wizard: IWizard } & {
  defaultValue?: string | string[];
  onSubmit?: IQuestionSubmit;
  onDirty?: () => void;
  children?: React.ReactElement | React.ReactElement[];
  isHideSubmit?: boolean;
};

export type IQuestionWrapperProps = IQuestion & { wizard: IWizard } & {
  isValid?: boolean;
  children?: React.ReactElement | React.ReactElement[] | any;
  submitButtonProps?: ButtonProps &
    LinkProps & { ref?: React.RefObject<HTMLButtonElement> };
  questionProps?: BoxProps;
};

export interface IAnimationEvent {
  onAnimationStart?: () => void;
  onAnimationComplete?: () => void;
}

export type IQuestionTypesProps = IQuestionProps & {
  wizard: IWizard;
  index?: number;
  lastIndex?: number;
} & IAnimationEvent;

export interface ISurveyResponse {
  id: string;
}
