import { ComponentStyleConfig } from "@chakra-ui/react";

const Select: ComponentStyleConfig = {
  baseStyle: {
    width: "auto",
    field: {
      fontWeight: 400,
      color: "rgba(0, 0, 0, 0.7)",
      background: "white",
      fontSize: "14px",
      borderRadius: "4px",
      _focus: {
        boxShadow: "none",
        background: "white",
      },
    },
  },
  sizes: {
    lg: {
      field: {
        fontSize: "24px",
        minHeight: "54px",
        borderRadius: "10px",
        minWidth: "413px",
        boxShadow: "0px 6px 24px 2px rgba(0, 0, 0, 0.25)",
      },
    },
    sm: {
      field: {
        fontSize: "14px",
        borderRadius: "4px",
        minHeight: "28px",
        minWidth: "285px",
        boxShadow: "0px 4px 18px 1px rgba(0, 0, 0, 0.25)",
      },
    },
  },
  variants: {
    filled: {
      field: {
        border: "2px solid",
        background: "white",
      },
    },
  },
  defaultProps: {
    size: "sm",
    variant: "filled",
  },
};

export default Select;
