import { useSurveyContext } from "../context/SurveyContext";

const useHeaderHeight = () => {
  const { headerRef } = useSurveyContext();

  const headerHeight = headerRef?.current?.clientHeight || 0;

  return headerHeight;
};

export default useHeaderHeight;
