import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Text,
  chakra,
  shouldForwardProp,
} from "@chakra-ui/react";
import React from "react";
import CustomButton from "../Button";
import { IQuestionWrapperProps } from "../../types";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import NextArrow from "../SVG/NextArrow";
import { setDirection } from "../../redux/reducers/survey";
import { AnimatePresence, isValidMotionProp, motion } from "framer-motion";
import BackArrow from "../SVG/BackArrow";

const MotionBack = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const MotionSkip = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const QuestionWrapper: React.FC<IQuestionWrapperProps> = ({
  icon,
  button_copy,
  copy_alignment,
  copy,
  button_icon,
  isValid,
  children,
  submitButtonProps,
  questionProps,
  skip_copy,
  skipRoute,
  wizard,
  skip_question,
  mandatory,
}) => {
  const dispatch = useAppDispatch();

  const theme = useAppSelector((state) => state.theme.theme);

  const { isFinished } = useAppSelector((state) => state.survey);

  const showPreviousStep = (wizard?.nPreviousSteps as number) >= 1;

  const showSkipButton =
    (wizard?.stepIndex() as number) < (wizard?.wizardLength() as number) - 1 &&
    !mandatory &&
    skip_question;

  return (
    <Flex direction={"column"} alignItems="center">
      {icon && (
        <Image
          src={icon}
          mb={{ base: "15px", lg: "22px" }}
          maxW={{ base: "30px", lg: "65px" }}
          maxH={{ base: "30px", lg: "65px" }}
        />
      )}

      <Box
        color={theme?.textColor}
        textAlign={copy_alignment}
        fontSize={{ base: "20px", lg: "32px" }}
        lineHeight={{ base: "23px", lg: "45px" }}
        mb={{ base: "49px", lg: "76px" }}
        width={"100%"}
        {...questionProps}
      >
        <Box
          dangerouslySetInnerHTML={{ __html: copy }}
          sx={{
            "> *": {
              textAlign: "center",
              fontSize: {
                base: "20px",
                lg: "32px",
              },
            },
            "p:not(:last-child)": {
              mb: {
                base: "12px",
                lg: "20px",
              },
            },
          }}
        ></Box>
      </Box>
      {children}
      <Flex
        mt={{ base: "51px", lg: "103px" }}
        alignItems="center"
        w="100%"
        justifyContent="center"
      >
        <Box flex={1} display={{ lg: "none" }}>
          <Flex
            flex={1}
            justifyContent={{
              base: showSkipButton ? "flex-start" : "flex-end",
              lg: "flex-start",
            }}
            position="relative"
          >
            <AnimatePresence>
              {!isFinished && showPreviousStep && (
                <MotionBack
                  layout
                  initial={{
                    x: -150,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                  }}
                  exit={{
                    x: -150,
                    opacity: 0,
                  }}
                >
                  <HStack
                    spacing={{ base: "2px", lg: "4px" }}
                    alignItems="center"
                    as={Button}
                    minH="0"
                    variant="link"
                    onClick={() => {
                      wizard?.previousStep();
                      dispatch(setDirection(-1));
                    }}
                    transform={{
                      base: showSkipButton ? "translateX(0)" : "translateX(0)",
                      lg: "translateX(0)",
                    }}
                  >
                    <BackArrow color={theme.textColor} />
                    <Text color={theme.textColor}>Back</Text>
                  </HStack>
                </MotionBack>
              )}
            </AnimatePresence>
          </Flex>
        </Box>
        <CustomButton
          button_icon={button_icon}
          type="submit"
          disabled={!isValid}
          {...submitButtonProps}
        >
          {button_copy || "Submit"}
        </CustomButton>
        <Box flex={1} display={{ lg: "none" }}>
          <Flex flex={1} justifyContent="flex-end" h="100%">
            <AnimatePresence>
              {showSkipButton && (
                <MotionSkip
                  initial={{
                    x: 150,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                  }}
                  exit={{
                    x: 150,
                    opacity: 0,
                  }}
                  // @ts-ignore
                  transition={{
                    duration: 0.6,
                  }}
                >
                  <Button
                    minHeight="0"
                    variant="link"
                    onClick={() => {
                      if (skipRoute) {
                        wizard?.goToStep(skipRoute);
                      } else {
                        wizard?.nextStep();
                      }

                      dispatch(setDirection(1));
                    }}
                  >
                    <HStack
                      spacing={{ base: "2px", lg: "4px" }}
                      alignItems="center"
                    >
                      <Text color={theme.textColor}>{skip_copy || "Skip"}</Text>
                      <NextArrow color={theme.textColor} />
                    </HStack>
                  </Button>
                </MotionSkip>
              )}
            </AnimatePresence>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default QuestionWrapper;
