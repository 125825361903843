import React, {
  useMemo,
  useRef,
  useEffect,
  useState,
  useCallback,
} from "react";
import Statement from "../../../components/questionTypes/Statement";
import Emoticon from "../../../components/questionTypes/Emoticon";
import SingleSelect from "../../../components/questionTypes/SingleSelect";
import CommentBox from "../../../components/questionTypes/CommentBox";
import PresetData from "../../../components/questionTypes/PresetData";
import SingleInput from "../../../components/questionTypes/SingleInput";
import DatetimePicker from "../../../components/questionTypes/DatetimePicker";
import MultiSelect from "../../../components/questionTypes/MultiSelect";
import {
  IQuestionProps,
  IQuestionTypesProps,
  QuestionType,
} from "../../../types";
import AnimatedStep from "../../../components/AnimatedStep";
import { Box, Container, Flex } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setQuestionHeight } from "../../../redux/reducers/theme";
import { footerOffset } from "../../../constants/global";
import useBottomInfoHeight from "../../../hooks/useBottomInfoHeight";
import useHeaderHeight from "../../../hooks/useHeaderHeight";

const QuestionTypes: React.FC<IQuestionTypesProps> = ({
  wizard,
  onAnimationStart,
  onAnimationComplete,
  index,
  lastIndex,
  ...props
}) => {
  const isLast = useMemo(() => {
    return wizard.stepIndex() + 1 === wizard.wizardLength();
  }, [wizard]);

  const Questions = useMemo(
    () => (props: IQuestionProps) => {
      switch (props.type) {
        case QuestionType.STATEMENT:
          return <Statement {...props} />;
        case QuestionType.EMOTICON:
          return <Emoticon {...props} />;
        case QuestionType.SINGLE_SELECT:
          return <SingleSelect {...props} />;
        case QuestionType.MULTI_SELECT:
          return <MultiSelect {...props} />;
        case QuestionType.COMMENT_BOX:
          return <CommentBox {...props} />;
        case QuestionType.PRESET_DATA:
          return <PresetData {...props} />;
        case QuestionType.SINGLE_INPUT:
          return <SingleInput {...props} />;
        case QuestionType.DATETIME_PICKER:
          return <DatetimePicker {...props} />;
        default:
          return null;
      }
    },
    []
  );

  const dispatch = useAppDispatch();

  const bottomInfoHeight = useBottomInfoHeight();

  const headerHeight = useHeaderHeight();

  const { answers } = useAppSelector((state) => state.survey);

  const anwerKeys = answers ? Object.keys(answers) : [];

  const isIdInAswers = anwerKeys.includes(props.id || "");

  const questionContainerRef = useRef<HTMLDivElement>(null);

  const handleResize = useCallback(() => {
    dispatch(
      setQuestionHeight(questionContainerRef?.current?.clientHeight || 0)
    );
  }, [dispatch, questionContainerRef]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    setTimeout(() => {
      handleResize();
    });

    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  useEffect(() => {
    if (index === lastIndex) {
      handleResize();
    }
  }, [index, lastIndex, handleResize]);

  const [isHideSubmitNew, setIsHideSubmitNew] = useState(false);

  return (
    <Box
      position="absolute"
      left="50%"
      transform="translateX(-50%)"
      top="0"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      // minHeight = 100vh - headerHeight - outterPaddingTop - outterPaddingBottom - bottomInfoHeight - footerOffset
      minHeight={{
        base: `calc(100vh - ${headerHeight}px - 24px - 21px - ${bottomInfoHeight}px - ${footerOffset}px)`,
        lg: `calc(100vh - ${headerHeight}px - 66px - 33px - ${bottomInfoHeight}px - ${footerOffset}px)`,
      }}
      width="100%"
      ref={questionContainerRef}
    >
      <Container maxW="container.md">
        <Flex direction="column" justifyContent="center" w="100%">
          <AnimatedStep
            onAnimationStart={onAnimationStart}
            onAnimationComplete={() => {
              setIsHideSubmitNew(
                (props?.nextOnSelect && !isIdInAswers) || false
              );
              if (onAnimationComplete) {
                onAnimationComplete();
              }
            }}
          >
            <Questions
              wizard={wizard}
              isLast={isLast}
              isHideSubmit={
                (props?.nextOnSelect && !isIdInAswers) || isHideSubmitNew
              }
              {...props}
            />
          </AnimatedStep>
        </Flex>
      </Container>
    </Box>
  );
};

export default QuestionTypes;
