import { gql } from "@apollo/client";

export const SURVEY_RESPONSE_START = gql`
  mutation SurveyResponseStart($input: surveyResponseStartInput!) {
    surveyResponseStart(input: $input) {
      id
      surveyId
      status
      startedAt
      completedAt
    }
  }
`;

export const SURVEY_RESPONSE_COMPLETE = gql`
  mutation SurveyResponseComplete($input: surveyResponseCompleteInput!) {
    surveyResponseComplete(input: $input) {
      id
      surveyId
      status
      startedAt
      completedAt
    }
  }
`;
