import React from "react";

const BackArrow: React.FC<ISVG> = ({ color = "white" }) => {
  return (
    <>
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.4531 10C21.4531 10.6562 20.9375 11.1719 20.3281 11.1719L4.39063 11.1719L10.625 17.0781C11.0938 17.5 11.0938 18.25 10.6719 18.6719C10.25 19.1406 9.54688 19.1406 9.07813 18.7188L0.828125 10.8438C0.59375 10.6094 0.5 10.3281 0.5 10C0.5 9.71875 0.59375 9.4375 0.828125 9.20312L9.07812 1.32812C9.54688 0.90625 10.25 0.90625 10.6719 1.375C11.0938 1.79687 11.0938 2.54687 10.625 2.96875L4.39063 8.875L20.3281 8.875C20.9844 8.875 21.4531 9.39062 21.4531 10Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default BackArrow;
