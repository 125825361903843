import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const FrownEmoji: React.FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M24 0C10.6875 0 0 10.7812 0 24C0 37.3125 10.6875 48 24 48C37.2188 48 48 37.3125 48 24C48 10.7812 37.2188 0 24 0ZM31.5 16.5C33.0938 16.5 34.4062 17.9062 34.4062 19.5C34.4062 21.1875 33.0938 22.5 31.5 22.5C29.8125 22.5 28.4062 21.1875 28.4062 19.5C28.4062 17.9062 29.8125 16.5 31.5 16.5ZM16.4062 16.5C18 16.5 19.4062 17.9062 19.4062 19.5C19.4062 21.1875 18 22.5 16.4062 22.5C14.7188 22.5 13.4062 21.1875 13.4062 19.5C13.4062 17.9062 14.8125 16.5 16.4062 16.5ZM34.7812 37.5C34.6875 37.5 34.5938 37.5 34.4062 37.5C33.6562 37.5 33 37.0312 32.9062 36.375C32.9062 36.0938 31.5938 30 24 30C16.3125 30 15 36.0938 14.9062 36.375C14.7188 37.125 13.9688 37.6875 13.125 37.5C12.375 37.3125 11.8125 36.5625 12 35.7188C12.5625 32.7188 15.8438 27 24 27C32.0625 27 35.3438 32.7188 35.9062 35.7188C36.0938 36.5625 35.5312 37.3125 34.7812 37.5Z"
        fill="currentColor"
      />
    </Icon>
  );
};

FrownEmoji.defaultProps = {
  viewBox: "0 0 48 48",
  boxSize: { base: "38px", lg: "40px" },
};

export default FrownEmoji;
