import React from "react";
import { Global } from "@emotion/react";

const Fonts: React.FC = () => {
  return (
    <Global
      styles={`
      @font-face {
        font-family: 'gilroy';
        src: url('./fonts/gilroy-light-webfont.woff2') format('woff2'),
             url('./fonts/gilroy-light-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
      }
      
      @font-face {
        font-family: 'gilroy';
        src: url('./fonts/gilroy-extrabold-webfont.woff2') format('woff2'),
             url('./fonts/gilroy-extrabold-webfont.woff') format('woff');
        font-weight: bold;
        font-style: normal;
      }
      `}
    />
  );
};

export default Fonts;
