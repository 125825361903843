import { ComponentStyleConfig } from "@chakra-ui/react";

const TextArea: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 400,
    bgColor: "white",
    fontSize: "14px",
    border: "1px solid",
    borderColor: "#000000",
  },
  sizes: {
    lg: {
      borderRadius: "10px",
      fontSize: "24px",
      boxShadow: "0px 8px 24px 2px rgba(0, 0, 0, 0.25)",
      maxWidth: "660px",
      minHeight: "191px",
    },
    sm: {
      borderRadius: "8px",
      fontSize: "14px",
      boxShadow: "0px 4px 18px 1px rgba(0, 0, 0, 0.25)",
      minHeight: "208px",
    },
  },
  variants: {
    filled: {
      background: "white",
      _focus: {
        bgColor: "#ffffffcc",
      },
      _hover: {
        bgColor: "#ffffffcc",
      },
    },
  },
  defaultProps: {
    size: "sm",
    variant: "filled",
  },
};

export default TextArea;
