import React, { useEffect, useRef } from "react";
import { IQuestionProps } from "../../../types";
import { Controller, useForm } from "react-hook-form";
import Emoticons from "./Emoticons";
import QuestionWrapper from "../../QuestionWrapper";

const Emoticon: React.FC<IQuestionProps> = ({
  defaultValue,
  onSubmit,
  onDirty,
  isHideSubmit,
  ...restProps
}) => {
  const { id, options, mandatory, nextOnSelect, emoticon4Options } = restProps;

  const {
    handleSubmit,
    formState: { isDirty, isValid },
    trigger,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      value: defaultValue as string,
    },
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (isDirty && onDirty) {
      onDirty();
    }
  }, [isDirty, onDirty]);

  const buttonSubmitRef = useRef<HTMLButtonElement>(null);

  return (
    <form
      id={`question-${id}`}
      onSubmit={handleSubmit((formData) => {
        if (onSubmit) {
          const selectedData = options?.find(
            (elm) => elm.value === formData.value
          );

          onSubmit(
            formData.value as string,
            false,
            false,
            selectedData?.routeId
          );
        }
      })}
    >
      <QuestionWrapper
        isValid={isValid}
        submitButtonProps={{
          display: isHideSubmit ? "none" : "block",
          disabled: !isValid && !nextOnSelect,
          ref: buttonSubmitRef,
        }}
        {...restProps}
      >
        <Controller
          control={control}
          rules={{ required: mandatory }}
          name="value"
          render={({ field: { onChange, value } }) => (
            <>
              {options && (
                <Emoticons
                  options={options}
                  onChange={(value) => {
                    onChange(value);
                    if (nextOnSelect) {
                      buttonSubmitRef.current?.click();
                    }
                  }}
                  value={value}
                  numberOfOptions={emoticon4Options ? 4 : 5}
                />
              )}
            </>
          )}
        />
      </QuestionWrapper>
    </form>
  );
};

export default Emoticon;
