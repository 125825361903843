import { Box, Image } from "@chakra-ui/react";
import { getImage } from "../../helpers/global";
import { useAppSelector } from "../../redux/store";
import CustomText from "../CustomText";

const Footer: React.FC = () => {
  const { theme } = useAppSelector((state) => state.theme);
  const { survey } = useAppSelector((state) => state.survey);

  return (
    <Box position="absolute" right="0" bottom="0">
      {survey && survey.teamWhiteLabelLogo ? (
        <Box>
          <Image maxH="50px" src={getImage(survey.teamWhiteLabelLogo)} />
        </Box>
      ) : survey && survey.teamWhiteLabelText ? (
        <CustomText
          fontSize={{ base: "14px", lg: "24px" }}
          fontFamily="body"
          textAlign="right"
        >
          {survey.teamWhiteLabelText}
        </CustomText>
      ) : (
        <CustomText
          fontSize={{ base: "14px", lg: "24px" }}
          fontFamily="body"
          textAlign="right"
        >
          <Box as="span" color={theme.textColor}>
            Powered by
          </Box>{" "}
          <Box as="span" fontWeight="bold" color={theme.textColor}>
            Gather
          </Box>
        </CustomText>
      )}
    </Box>
  );
};

export default Footer;
