import { useRadioGroup, Flex, Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useMemo } from "react";
import { IOption } from "../../../types";
import useDefaultIcons from "./data";
import RadioIcon from "./RadioIcon";

const MotionHStack = motion(Flex);

interface EmoticonsProps {
  numberOfOptions: number;
  options: IOption[];
  value?: string;
  onChange?: (value: string) => void;
}

const maxOptions = 5;

const Emoticons: React.FC<EmoticonsProps> = ({
  numberOfOptions = maxOptions,
  options,
  value,
  onChange,
}) => {
  const { getRadioProps, getRootProps } = useRadioGroup({
    value,
    onChange,
  });

  const defaultEmoticons = useDefaultIcons();

  const emoticonContVariants = {
    initial: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const updatedDefaultEmoticons = useMemo(() => {
    const emoticons = defaultEmoticons.map((elm) => ({
      ...elm,
      isHidden: false,
    }));

    const diff = maxOptions - numberOfOptions;

    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        emoticons[numberOfOptions + i].isHidden = true;
      }
    }

    return emoticons;
  }, [defaultEmoticons, numberOfOptions]);

  return (
    <MotionHStack
      {...getRootProps()}
      mt="-48px"
      flexDirection={{ base: "column", md: "row" }}
      flexWrap={{ base: "wrap", lg: "nowrap" }}
      w="100%"
      justifyContent="center"
      variants={emoticonContVariants}
      animate="visible"
      initial="initial"
      sx={{
        "> *": {
          width: { md: "50%", lg: "20%" },
          mt: {
            base: "38px",
            lg: "48px",
          },
          px: {
            base: "20px",
            lg: "30px",
          },
        },
      }}
    >
      {updatedDefaultEmoticons.map((emoticon, index) => (
        <Box key={index} display={emoticon.isHidden ? "none" : "block"}>
          <RadioIcon
            {...getRadioProps({
              value:
                options && options[index]
                  ? options[index].value
                  : emoticon.copy,
            })}
            icon={
              options && options[index] && options[index].icon
                ? options[index].icon
                : ""
            }
            defaultIcon={emoticon.icon}
            copy={
              options && options[index] && options[index].label
                ? options[index].label
                : emoticon.copy
            }
          />
        </Box>
      ))}
    </MotionHStack>
  );
};

export default Emoticons;
