import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const AverageEmoji: React.FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M24 0C10.6875 0 0 10.7812 0 24C0 37.3125 10.6875 48 24 48C37.2188 48 48 37.3125 48 24C48 10.7812 37.2188 0 24 0ZM16.4062 16.5C18 16.5 19.4062 17.9062 19.4062 19.5C19.4062 21.1875 18 22.5 16.4062 22.5C14.7188 22.5 13.4062 21.1875 13.4062 19.5C13.4062 17.9062 14.8125 16.5 16.4062 16.5ZM33 34.5H14.9062C12.8438 34.5 12.8438 31.5 14.9062 31.5H32.9062C34.9688 31.5 34.9688 34.5 33 34.5ZM31.5 22.5C29.8125 22.5 28.4062 21.1875 28.4062 19.5C28.4062 17.9062 29.8125 16.5 31.5 16.5C33.0938 16.5 34.4062 17.9062 34.4062 19.5C34.4062 21.1875 33.0938 22.5 31.5 22.5Z"
        fill="currentColor"
      />
    </Icon>
  );
};

AverageEmoji.defaultProps = {
  viewBox: "0 0 48 48",
  boxSize: { base: "38px", lg: "40px" },
};

export default AverageEmoji;
