import React from "react";

const NextArrow: React.FC<ISVG> = ({ color = "white" }) => {
  return (
    <>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.046876 10C0.0468761 9.34375 0.562501 8.82812 1.17188 8.82812L17.1094 8.82812L10.875 2.92187C10.4063 2.5 10.4063 1.75 10.8281 1.32812C11.25 0.859374 11.9531 0.859374 12.4219 1.28125L20.6719 9.15625C20.9063 9.39062 21 9.67188 21 10C21 10.2812 20.9063 10.5625 20.6719 10.7969L12.4219 18.6719C11.9531 19.0937 11.25 19.0937 10.8281 18.625C10.4063 18.2031 10.4063 17.4531 10.875 17.0312L17.1094 11.125L1.17188 11.125C0.515626 11.125 0.046876 10.6094 0.046876 10Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default NextArrow;
