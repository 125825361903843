import axios from "axios";

export const tokenRetrievalRequest = () => {
  return axios.get(
    `${process.env.REACT_APP_PUBLIC_DOMAIN}/sanctum/csrf-cookie`,
    {
      withCredentials: true,
    }
  );
};

export const getImage = (path) => {
  return `${process.env.REACT_APP_PUBLIC_IMAGES_S3}/${path}`;
};
