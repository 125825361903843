import { Flex } from "@chakra-ui/react";
import React from "react";
import Header from "../Header";
import DefaultLogo from "../../assets/images/gather.png";
import { QuestionLayoutProps } from "../../types";
import { useAppSelector } from "../../redux/store";
import { getImage } from "../../helpers/global";
import Footer from "../Footer";
import { footerOffset } from "../../constants/global";
import useHeaderHeight from "../../hooks/useHeaderHeight";

const QuestionLayout: React.FC<QuestionLayoutProps> = ({
  children,
  wizard,
  step,
}) => {
  const { theme, questionHeight } = useAppSelector((state) => state.theme);

  const { survey, selectedQuestion } = useAppSelector((state) => state.survey);

  const headerHeight = useHeaderHeight();

  return (
    <Flex
      direction="column"
      h={`${questionHeight + headerHeight + footerOffset}px`}
      transition="height 0.5s ease"
      position="relative"
    >
      <Header
        logo={survey && survey.logo ? getImage(survey.logo) : DefaultLogo}
        logo_position={theme.logoPosition}
        back_copy="Back"
        skip_copy={selectedQuestion?.skip_copy}
        skipRoute={selectedQuestion?.skipRoute}
        skip_color={theme.textColor}
        back_color={theme.textColor}
        skip_question={
          !selectedQuestion?.mandatory && selectedQuestion?.skip_question
        }
        wizard={wizard}
        step={step}
      />
      <Flex
        flex={1}
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        {children}
      </Flex>
      <Footer />
    </Flex>
  );
};

export default QuestionLayout;
