import { Box, Link } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { IQuestionProps } from "../../../types";
import { useForm } from "react-hook-form";
import QuestionWrapper from "../../QuestionWrapper";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setIsFinished } from "../../../redux/reducers/survey";
import CustomButton from "../../Button";
import { useQuestionsWizardContext } from "../../../context/QuestionsWizardContext";

const Statement: React.FC<IQuestionProps> = ({
  onSubmit,
  onDirty,
  ...restProps
}) => {
  const { id, button_copy, routeToQuestion, externalLink, theEnd } = restProps;

  const {
    handleSubmit,
    formState: { isDirty, isValid },
    trigger,
    setValue,
  } = useForm({
    mode: "onChange",
  });

  const { survey } = useAppSelector((state) => state.survey);

  const { restartSurvey } = useQuestionsWizardContext();

  const routeId = routeToQuestion;

  useEffect(() => {
    setValue("value", true, { shouldDirty: true });
    trigger();
  }, [setValue, trigger]);

  const buttonSubmitRef = useRef<HTMLButtonElement>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isDirty && onDirty && buttonSubmitRef) {
      onDirty();
      if (theEnd) {
        dispatch(
          setIsFinished({ isFinished: true, isQuestionAsFinishedScreen: true })
        );
        buttonSubmitRef?.current?.click();
      }
    }
  }, [isDirty, onDirty, theEnd, dispatch, buttonSubmitRef]);

  const externalLinkRef = useRef<HTMLButtonElement>(null);

  return (
    <form
      id={`question-${id}`}
      onSubmit={handleSubmit((formData: any) => {
        if (externalLink && !theEnd) {
          externalLinkRef.current?.click();
        }

        if (onSubmit !== undefined) {
          onSubmit(formData.value, theEnd, true, routeId);
        }
      })}
    >
      <QuestionWrapper
        isValid={isValid}
        questionProps={{
          mb: 0,
          sx: {
            p: {
              mb: 4,
              "&:last-child": {
                mb: 0,
              },
            },
          },
        }}
        submitButtonProps={{
          display: theEnd ? "none" : "block",
          ref: buttonSubmitRef,
        }}
        {...restProps}
      />

      {(externalLink || theEnd) && (
        <Box
          textAlign="center"
          display={externalLink && theEnd ? "block" : "none"}
        >
          <CustomButton
            mt={{ base: "51px", lg: "103px" }}
            as={Link}
            href={externalLink}
            target="_blank"
            ref={externalLinkRef}
            className="mt-3"
          >
            {button_copy || "Continue"}
          </CustomButton>
        </Box>
      )}

      {theEnd && survey?.restartSurvey && (
        <Box mt="20px" textAlign="center">
          <CustomButton onClick={() => restartSurvey()}>Restart</CustomButton>
        </Box>
      )}
    </form>
  );
};

export default Statement;
