import { Button, ButtonProps, LinkProps } from "@chakra-ui/react";
import { forwardRef } from "react";
import NextArrow from "../../components/SVG/NextArrow";
import { useAppSelector } from "../../redux/store";

interface CustomProps {
  button_icon?: boolean;
}

export const CustomButton = forwardRef<
  HTMLButtonElement,
  ButtonProps & CustomProps & LinkProps
>(({ children, button_icon, ...rest }, ref) => {
  const theme = useAppSelector((state) => state.theme.theme);

  const localProps: ButtonProps = {};
  if (button_icon) {
    localProps.rightIcon = <NextArrow color={theme.buttonColor} />;
  }

  return (
    <Button
      ref={ref}
      size={{ base: "sm", lg: "lg" }}
      bgColor={theme?.buttonBackgroundColor}
      _hover={{
        bgColor: `${theme?.buttonBackgroundColor}`,
        opacity: 0.7,
        textDecoration: "none",
      }}
      _active={{
        bgColor: `${theme?.buttonBackgroundColor}`,
      }}
      sx={{
        svg: {
          height: "20px",
          width: "18px",
        },
        "@media (min-width: 64rem)": {
          svg: {
            height: "32px",
            width: "28px",
          },
        },
      }}
      {...localProps}
      color={theme.buttonColor}
      {...rest}
    >
      {children}
    </Button>
  );
});

export default CustomButton;
