import React, { createContext, FC, useContext, ReactNode } from "react";

interface IAppContext {
  restartSurvey: () => void;
}

const defaultState = { restartSurvey: () => {} };

const QuestionsWizardContext = createContext<IAppContext>(defaultState);

interface SurveyProviderProps {
  children: ReactNode;
  restartSurvey: () => void;
}
const QuestionsWizardProvider: FC<SurveyProviderProps> = ({
  children,
  restartSurvey,
}) => {
  return (
    <QuestionsWizardContext.Provider value={{ restartSurvey }}>
      {children}
    </QuestionsWizardContext.Provider>
  );
};

const useQuestionsWizardContext = () => useContext(QuestionsWizardContext);

export { QuestionsWizardProvider, useQuestionsWizardContext };
