import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IQuestion, ISurvey, ISurveyResponse } from "../../types";

type SurveySliceState = {
  selectedQuestionId?: string;
  selectedQuestion?: IQuestion;
  survey?: ISurvey;
  questions: IQuestion[];
  totalQuestions: number;
  answers?: { [key: string]: string | string[] };
  surveyResponse?: ISurveyResponse;
  isFinished?: boolean;
  isQuestionAsFinishedScreen?: boolean;
  direction: number;
};

const initialState: SurveySliceState = {
  survey: undefined,
  questions: [],
  totalQuestions: 0,
  direction: 1,
};

const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    setSelectedQuestionId: (state, action) => {
      const questionId = action.payload;
      state.selectedQuestionId = action.payload;
      state.selectedQuestion = state.questions?.find(
        (elm) => elm.id === questionId
      );
    },
    setSurvey: (state, action) => {
      state.survey = action.payload;
    },
    setQuestions: (state, action: PayloadAction<IQuestion[]>) => {
      state.questions = action.payload;
      state.totalQuestions = action.payload.length;
    },
    setAnimationStart(state, action: PayloadAction<number>) {
      state.questions[action.payload].isAnimationRunning = true;
    },
    setAnimationComplete(state, action: PayloadAction<number>) {
      state.questions[action.payload].isAnimationRunning = false;
    },
    setAnswer: (
      state,
      action: PayloadAction<{ key: string; value: string | string[] }>
    ) => {
      if (state.answers === undefined) {
        state.answers = {};
      }

      state.answers[action.payload.key] = action.payload.value;
    },
    clearAnswers:(state)=>{
      state.answers = undefined
    },
    setSurveyResponse: (state, action: PayloadAction<ISurveyResponse | undefined>) => {
      state.surveyResponse = action.payload;
    },
    setIsFinished: (state, action: PayloadAction<{isFinished?: boolean; isQuestionAsFinishedScreen?: boolean }>) => {
      state.isFinished = action.payload.isFinished;
      state.isQuestionAsFinishedScreen = action.payload.isQuestionAsFinishedScreen || false;
    },
    setDirection: (state, action: PayloadAction<number>) => {
      state.direction = action.payload;
    },
  },
});

export const {
  setSelectedQuestionId,
  setSurvey,
  setQuestions,
  setAnswer,
  clearAnswers,
  setSurveyResponse,
  setIsFinished,
  setDirection,
  setAnimationStart,
  setAnimationComplete,
} = surveySlice.actions;

export default surveySlice.reducer;
