import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ITheme {
  primaryColor?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  logo?: string;
  logoPosition?: "LEFT" | "CENTER" | "RIGHT";
  textColor?: string;
  buttonBackgroundColor?: string;
  buttonColor?: string;
}

interface SurveySliceState {
  theme: ITheme;
  questionHeight: number;
}

const initialState: SurveySliceState = {
  theme: {
    primaryColor: "",
    backgroundColor: "",
    backgroundImage: "",
    logo: "",
    logoPosition: "LEFT",
    textColor: "",
    buttonBackgroundColor: "",
    buttonColor: "",
  },
  questionHeight: 0,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    storeThemeSettings: (state, action: PayloadAction<ITheme>) => {
      state.theme = action.payload;
    },
    setQuestionHeight: (state, action: PayloadAction<number>) => {
      state.questionHeight = action.payload;
    },
  },
});

export const { storeThemeSettings, setQuestionHeight } = themeSlice.actions;
export default themeSlice.reducer;
